import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";

import api from "../Api";

export const CONSTANTS = {};

const INITIAL_STATE = {};

const reducer = (state, action) => {
  switch (action.type) {
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useSpotsStore = () => {
  const { dispatch, state } = useContext(Context);

  const updateExcelScheme = useCallback(
    async (id, data) => await api.put(`/spots/${id}/excelscheme`, data),
    [],
  );

  return {
    state,
    updateExcelScheme,
  };
};
