import React, { useCallback, useEffect, useMemo, useState } from "react";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Link, useLocation } from "react-router-dom";

import { useForm } from "react-hook-form";

import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import DatePicker from "@/components/DatePicker";
import Guide from "@/components/Guide";
import Table from "@/components/Table";

import ExcelAfterBulkRegister from "@/components/excel/AfterBulkRegister";
import ExcelForPrint from "@/components/excel/ForPrint";

import daumAddress from "@/lib/daum-address";

import ExcelUploadModal from "@/modals/ExcelUploadModal";
import ExcelSchemeModal from "@/modals/ExcelSchemeModal";
import WaybillModal from "@/modals/WaybillModal";

import {
  DELIVERY_TYPE_NORMAL,
  DELIVERY_TYPE_PICKUP,
  DELIVERY_TYPE_RETURN,
  DELIVERY_STATUS_9,
} from "@/Models/Delivery";
import Spot from "@/Models/Spot";

import {
  useAgPostalCodesStore,
  useDeliveriesStore,
  useDeliveriesNewStore,
  useLoadingStore,
  useModalStore,
  useProfileStore,
} from "@/stores/hooks";

const BodyContent = styled.div``;
const Container = styled.div`
  width: 940px;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
`;

const TitleWrap = styled(Row)`
  padding: 20px 0;
  margin: 0 0 20px;
  justify-content: space-between;
  align-items: center;
`;
const TitleAppliedWrap = styled(TitleWrap)`
  align-items: flex-end;
`;
const TitleGroup = styled.div``;
const Title = styled.h2`
  margin: 0;
  font-size: 24px;
  font-weight: bold;
`;
const Desc = styled.p`
  margin-top: 20px;
  margin-bottom: 0;
`;
const ButtonGroup = styled.div``;

const TitleButton = styled(Button)`
  margin-left: 5px;
`;

const FaildTitle = styled.div`
  background-color: #ea2845;
  color: #fff;
  padding: 5px 10px;
  margin-bottom: 10px;
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h4`
  font-size: 18px;
  padding-bottom: 5px;
  border-bottom: 1px solid #555;
  margin-bottom: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const CorpInfo = styled.div`
  color: #555;
  font-size: 16px;
`;

const CorpSelectWrap = styled.div`
  margin-left: 10px;
`;

const FormCol = styled(Col)``;
const FormColFlex = styled(FormCol)`
  align-items: center;
  display: flex;
`;

const FormGroupDatePicker = styled(Form.Group)`
  margin-top: -0.7rem;
  margin-right: -5px;
  margin-left: -5px;
`;

const FormLabel = styled(Form.Label)`
  font-weight: bold;
`;
const FormControl = styled(Form.Control)`
  width: ${({ width }) => width || "100%"};
`;
const FormControlTextarea = styled(FormControl)`
  resize: none;
`;
const FormControlWrap = styled.div`
  display: flex;
  width: ${({ width }) => width || "100%"};
`;
const SearchButton = styled(Button)`
  margin-left: 5px;
`;

const DatePickerCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;
const DatePickerCustom = styled(DatePicker)`
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:disabled {
    background-color: #e9ecef;
    opacity: 1;
  }
`;

const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`;

const FormNotice = styled.p`
  font-weight: bold;
`;

const TextHighlight = styled.span`
  color: red;
  font-size: inherit;
`;

const FormSelect = styled(Form.Control)`
  width: 160px;
  padding: 5px 10px;
`;

const ExcelButton = ({ selectedSpot, onSubmitBulk }) => {
  const { openModal } = useModalStore();

  function handleClick() {
    openModal(
      <ExcelUploadModal
        key="excel-upload-modal"
        title="대량 접수"
        onSubmit={onSubmitBulk}
        downloadPath="두발히어로_대량접수샘플"
        spot={selectedSpot}
        validation={true}
      />,
    );
  }

  return (
    <TitleButton onClick={handleClick} type="button" width="120px">
      대량 접수
    </TitleButton>
  );
};

const ExcelUpdateButton = ({ onSubmitUpdateBulk }) => {
  const { openModal } = useModalStore();

  function handleClick() {
    openModal(
      <ExcelUploadModal
        key="excel-upload-modal"
        title="대량 수정"
        onSubmit={onSubmitUpdateBulk}
        downloadPath="NONE"
      />,
    );
  }

  return (
    <TitleButton onClick={handleClick} type="button" width="120px">
      대량 수정
    </TitleButton>
  );
};

const ExcelReturnButton = ({ onSubmitReturnBulk }) => {
  const { openModal } = useModalStore();

  function handleClick() {
    openModal(
      <ExcelUploadModal
        key="excel-return-upload-modal"
        title="반품 대량 접수"
        onSubmit={onSubmitReturnBulk}
        downloadPath="두발히어로_반품대량접수샘플"
      />,
    );
  }

  return (
    <TitleButton
      onClick={handleClick}
      type="button"
      width="120px"
      variant="dark"
    >
      반품 대량 접수
    </TitleButton>
  );
};

const ExcelSchemeButton = ({ spot, onAfterSubmit }) => {
  const { openModal } = useModalStore();

  const handleClick = () => {
    openModal(
      <ExcelSchemeModal
        key="excel-scheme-modal"
        spot={spot}
        onAfterSubmit={onAfterSubmit}
      />,
    );
  };

  return (
    <TitleButton
      onClick={handleClick}
      type="button"
      width="140px"
      variant="secondary"
    >
      대량 접수 파일설정
    </TitleButton>
  );
};

export default function DeliveriesNew() {
  const { agPostalCodes } = useAgPostalCodesStore().state;
  const { ...agPostalCodesActions } = useAgPostalCodesStore();

  const { user } = useProfileStore().state;
  const { ...profileActions } = useProfileStore();

  const { ...deliveriesActions } = useDeliveriesStore();
  const { state, ...actions } = useDeliveriesNewStore();

  const { startLoading, finishLoading } = useLoadingStore();
  const { openModal } = useModalStore();

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const typeByQuery = query.get("type");
  const bookIdByQuery = query.get("bookId");

  const [fetched, setFetched] = useState(false);

  const {
    clearErrors,
    errors,
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm();

  useEffect(() => {
    resetBulk();
    fetchUser();

    setFetched(true);
  }, []);

  useEffect(() => {
    fetchAgPostalCodes();
  }, []);

  useEffect(() => {
    if (fetched && state.bulkApplied && state.bulkDeliveriesForPrint.length) {
      // 대량 접수 이후 운송장 출력
      handleClickBulkWaybill();
    }
  }, [fetched, state.bulkApplied, state.bulkDeliveriesForPrint]);

  /**
   * 접수점 선택
   */
  const [selectedSpot, setSelectedSpot] = useState(null);

  const fetchAgPostalCodes = useCallback(async () => {
    if (!agPostalCodes.length) {
      try {
        await agPostalCodesActions.fetchAll();
      } catch (e) {
        console.log(e);
      }
    }
  }, [agPostalCodesActions.fetchAll, agPostalCodes]);

  const fetchUser = useCallback(async (_selectedSpot) => {
    try {
      const user = await profileActions.fetchUser();

      let spot = null;

      if (_selectedSpot) {
        spot = user?.spots?.find((spot) => spot.id === _selectedSpot?.id);
      } else {
        spot = user?.spots?.length ? user?.spots[0] : null;
      }

      setSelectedSpot(spot ? new Spot(spot) : null);
    } catch (e) {
      // console.log(e);
    }
  }, []);

  useEffect(() => {
    reset({
      productCategory: "의류",
      paymentMethod: "pre-pay",
    });
  }, [state.deliveryType]);

  useEffect(() => {
    if (typeByQuery === "return" && bookIdByQuery) {
      actions.setDeliveryType(DELIVERY_TYPE_RETURN);

      setTimeout(() => {
        setValue("returnDeliveryId", bookIdByQuery);
        handleClickSearchBookId(bookIdByQuery);
      }, 400);
    }
  }, [typeByQuery, bookIdByQuery]);

  const handleClickAddress = useCallback(async () => {
    const result = await daumAddress();

    setValue("customerAddress", result.roadAddress);
    setValue("customerAddressPostalCode", result.zonecode);

    clearErrors(["customerAddress"]);
  }, [daumAddress]);

  const handleChangeDeliveryType = useCallback(
    (e) => {
      actions.setDeliveryType(e.target.value);
    },
    [actions.setDeliveryType],
  );

  // useEffect(() => {
  //   setSelectedSpot(user?.spots?.length ? new Spot(user?.spots[0]) : null);
  // }, [user]);

  const handleChangeSpot = useCallback(
    (e) => {
      const _spot = user.spots?.find(
        (spot) => Number(spot.id) === Number(e.target.value),
      );
      setSelectedSpot(_spot ? new Spot(_spot) : null);
    },
    [user],
  );

  const [receiptType, setReceiptType] = useState("1");
  const [dateSelectable, setDateSelectable] = useState(false);
  const handleChangeReceiptType = useCallback((e) => {
    setReceiptType(e.target.value);
    setDateSelectable(e.target.value === "2");

    setValue("date", null);
    setValue("hour", null);
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      try {
        startLoading();

        let _data = { ...data };
        _data.type = state.deliveryType;
        _data.productPrice = +data.productPrice;

        if (selectedSpot) {
          _data.spotId = selectedSpot?.id;
        }

        const response = await actions.create(_data);
        const addressNotSupported =
          response.addressNotSupported || response.status === DELIVERY_STATUS_9;

        alert(
          `성공적으로 접수되었습니다. ${
            addressNotSupported ? "배송불가지역 1건" : ""
          }`,
        );

        // 운송장 출력
        if (response.bookId) {
          handleClickWaybill(response);
        }

        reset();
      } catch (e) {
        alert(e.message);
      }

      finishLoading();
    },
    [actions.create, state.deliveryType, receiptType, user, selectedSpot],
  );

  const onSubmitBulk = useCallback(
    async (arr) => {
      try {
        startLoading();
        let _data = {
          data: arr,
        };

        if (selectedSpot) {
          _data.spotId = selectedSpot?.id;
        }

        const response = await actions.createBulk(_data);

        const addressNotSupported = response.deliveriesForPrint?.filter(
          (el) => el.status === 9 || el.addressNotSupported,
        );

        window.alert(
          `총 ${response?.deliveriesForPrint?.length}건이 성공적으로 등록되었습니다, 배송불가지역 ${addressNotSupported?.length}건`,
        );
      } catch (e) {
        alert(e.message);
      }

      finishLoading();
    },
    [actions.createBulk, selectedSpot],
  );

  const onSubmitUpdateBulk = useCallback(
    async (arr) => {
      try {
        startLoading();
        const response = await actions.updateBulk(arr);

        window.alert(
          `${response?.success?.length}개의 배송을 업데이트 하였습니다.`,
        );
      } catch (e) {
        window.alert(`대량 수정에 실패했습니다. :${e.message}`);
      }

      finishLoading();
    },
    [actions.createReturnBulk],
  );

  const onSubmitReturnBulk = useCallback(
    async (arr) => {
      try {
        startLoading();
        await actions.createReturnBulk(arr);

        window.alert("반품 대량 접수되었습니다.");
      } catch (e) {
        window.alert(`반품 대량 접수에 실패했습니다. :${e.message}`);
      }

      finishLoading();
    },
    [actions.createReturnBulk],
  );

  const resetBulk = useCallback(() => {
    actions.resetBulk();
  }, [actions.resetBulk]);

  /**
   * 단건접수 운송장 출력
   */
  const handleClickWaybill = useCallback(
    (delivery) => {
      if (delivery.bookId && user.autoPrint) {
        openModal(
          <WaybillModal
            key="waybill-modal"
            deliveries={[delivery]}
            onAfterSubmit={() => {}}
          />,
        );
      }
    },
    [user],
  );

  /**
   * 대량접수 운송장 출력
   */
  const handleClickBulkWaybill = useCallback(() => {
    if (state.bulkDeliveriesForPrint.length > 0 && user.autoPrint) {
      openModal(
        <WaybillModal
          key="waybill-modal"
          deliveries={state.bulkDeliveriesForPrint}
          onAfterSubmit={() => {}}
        />,
      );
    }
  }, [state.bulkDeliveriesForPrint, user]);

  const handleClickBulkWaybillForce = useCallback(() => {
    if (state.bulkDeliveriesForPrint.length > 0) {
      openModal(
        <WaybillModal
          key="waybill-modal"
          deliveries={state.bulkDeliveriesForPrint}
          onAfterSubmit={() => {}}
        />,
      );
    }
  }, [state.bulkDeliveriesForPrint]);

  // 배송 조회 : 반품 건
  const handleClickSearchBookId = useCallback(
    async (_returnDeliveryId) => {
      const returnDeliveryId =
        getValues().returnDeliveryId || _returnDeliveryId;

      if (returnDeliveryId) {
        try {
          startLoading();
          const delivery = await deliveriesActions.fetchByIdOnTheFly(
            returnDeliveryId,
          );

          const target = delivery.type === "1" ? "receiver" : "sender";
          // const etc4 = JSON.parse(delivery.etc4 || '{}');

          reset({
            ...getValues(),
            productCategory: delivery.productCategory,
            productName: delivery.productName,
            productPrice: delivery.productPrice,
            customerName: delivery[`${target}Name`],
            customerMobile: delivery[`${target}Mobile`],
            customerMobile2: delivery[`${target}Mobile2`],
            customerAddress: delivery[`${target}AddressRoad`],
            customerAddressDetail: delivery[`${target}AddressDetail`],
            customerAddressPostalCode: delivery[`${target}PostalCode`],
            memoFromCustomer: delivery.memoFromCustomer
              ? `(이전요청사항) ${delivery.memoFromCustomer}`
              : "",
          });

          // this.setState({
          //   deliveryType: '3',
          //   returnDeliveryId,
          //   etc4: delivery.etc4,
          //   returnDeliveryUpdated: true,
          //   spotId: delivery.spotId,
          // });
        } catch (e) {
          alert(
            "반품 접수 가능한 접수번호가 아닙니다. 확인 후 다시 접수 부탁드립니다.",
          );
        }

        finishLoading();
      } else {
        alert("접수번호를 입력해주세요.");
      }
    },
    [deliveriesActions.fetchByIdOnTheFly],
  );

  /**
   * 엑셀 스키마 저장 후 호출
   */
  const handleAfterSubmitExcelScheme = useCallback(() => {
    fetchUser(selectedSpot);
  }, [fetchUser, selectedSpot]);

  const addressNotSupported = state.bulkDeliveriesForPrint?.filter(
    (el) => el.addressNotSupported || el.status === DELIVERY_STATUS_9,
  );

  const columns = useMemo(
    () => [
      {
        Header: "사측 주문번호",
        accessor: "row.orderIdFromCorp",
        width: 100,
      },
      {
        Header: "수하인명",
        accessor: "row.customerName",
        width: 100,
      },
      {
        Header: "수하인 연락처",
        accessor: "row.customerMobile",
        width: 100,
      },
      {
        Header: "주소",
        accessor: "row.customerAddress",
        width: 120,
      },
      {
        Header: "상세주소",
        accessor: "row.customerAddressDetail",
        width: 100,
      },
      {
        Header: "물품명",
        accessor: "row.productName",
        width: 100,
      },
      {
        Header: "지불방식",
        accessor: "row.paymentMethod",
        width: 90,
      },
      {
        Header: "고객요청사항",
        accessor: "row.memoFromCustomer",
        width: 100,
      },
      {
        Header: "실패사유",
        accessor: "reason",
        width: 180,
      },
    ],
    [],
  );

  return (
    <BodyContent>
      <Guide />
      {state.bulkApplied ? (
        <Container>
          <TitleAppliedWrap>
            <TitleGroup>
              <Title>
                대량등록 총 {state.bulkAppliedCount}건 중{" "}
                {state.bulkDeliveriesForPrint?.length}건 등록되었습니다.{" "}
                {addressNotSupported?.length > 0 && (
                  <TextHighlight>
                    (배송불가지역 접수건 {addressNotSupported?.length}건)
                  </TextHighlight>
                )}
              </Title>
              <Desc>
                우측 접수다운로드 버튼 클릭 시 대량등록한 액셀파일의 접수건과
                같은 순서로
                <br />
                송장번호로 사용할 수 있는 예약번호가 추가 되어 있는 엑셀파일을
                다운로드할 수 있습니다.
              </Desc>
            </TitleGroup>
            <ButtonGroup>
              <TitleButton
                type="button"
                variant="outline-secondary"
                width="120px"
                onClick={handleClickBulkWaybillForce}
              >
                운송장 출력
              </TitleButton>
              {/** 접수 다운로드 */}
              <ExcelAfterBulkRegister
                data={state.bulkDeliveriesForDownload}
                spotCode={user.spotCode}
              />
              {/** 송장 출력용 엑셀 */}
              <ExcelForPrint data={state.bulkDeliveriesForDownload} />
            </ButtonGroup>
          </TitleAppliedWrap>
          <FaildTitle>
            등록 실패 {state.bulkDeliveriesFailed?.length || 0}건
            {state.bulkDeliveriesFailed?.length
              ? ` (실패 사유: ${state.bulkDeliveriesFailed[0].reason})`
              : ""}
          </FaildTitle>
          <Table
            responsive
            bordered
            hover
            columns={columns}
            data={state.bulkDeliveriesFailed}
            pagination={false}
            checkbox={false}
          />
          <ButtonGroup>
            <Button type="button" width="120px" onClick={resetBulk}>
              다시 접수하기
            </Button>
          </ButtonGroup>
        </Container>
      ) : (
        <Container>
          <TitleWrap>
            <Title>두발히어로 접수하기</Title>
            <ButtonGroup>
              <Link
                target="_blank"
                to={{
                  pathname:
                    "https://drive.google.com/file/d/10Uv-2j1K09IIPijwcknwra523--ibfyv/view",
                }}
              >
                <TitleButton
                  type="button"
                  variant="outline-secondary"
                  width="120px"
                >
                  대량 접수 안내
                </TitleButton>
              </Link>
              <ExcelButton
                selectedSpot={selectedSpot}
                onSubmitBulk={onSubmitBulk}
              />
              {(user.editableByWorksheet || user.updatable) && (
                <ExcelUpdateButton onSubmitUpdateBulk={onSubmitUpdateBulk} />
              )}
              <ExcelReturnButton onSubmitReturnBulk={onSubmitReturnBulk} />
              <ExcelSchemeButton
                spot={selectedSpot}
                onAfterSubmit={handleAfterSubmitExcelScheme}
              />
            </ButtonGroup>
          </TitleWrap>
          <Section>
            <SectionTitle>
              접수점 선택
              {user.spots?.length > 1 ? (
                <CorpSelectWrap>
                  <FormSelect
                    as="select"
                    name="spotId"
                    onChange={handleChangeSpot}
                    value={selectedSpot?.id}
                  >
                    {user.spots.map((spot) => {
                      return (
                        <option value={spot.id} key={spot.id}>
                          {spot.name}
                        </option>
                      );
                    })}
                  </FormSelect>
                </CorpSelectWrap>
              ) : (
                ""
              )}
            </SectionTitle>
            <CorpInfo>
              접수점: {selectedSpot?.name} / {selectedSpot?.address}{" "}
              {selectedSpot?.addressDetail}
              <br />
              운송장 연락처: {selectedSpot?.contactForLabel || user.contact}
            </CorpInfo>
          </Section>
          <Section>
            <SectionTitle>접수건 입력</SectionTitle>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group as={Row}>
                <FormLabel column xs="2">
                  배송 유형
                </FormLabel>
                <FormColFlex>
                  <Form.Check
                    inline
                    label="일반배송"
                    type="radio"
                    id="deliveryType-1"
                    value={DELIVERY_TYPE_NORMAL}
                    name="deliveryType"
                    onChange={handleChangeDeliveryType}
                    checked={state.deliveryType === DELIVERY_TYPE_NORMAL}
                  />
                  <Form.Check
                    inline
                    label="픽업배송(반송)"
                    type="radio"
                    id="deliveryType-2"
                    value={DELIVERY_TYPE_PICKUP}
                    name="deliveryType"
                    onChange={handleChangeDeliveryType}
                    checked={state.deliveryType === DELIVERY_TYPE_PICKUP}
                  />
                  <Form.Check
                    inline
                    label="배송완료건 반품"
                    type="radio"
                    id="deliveryType-3"
                    value={DELIVERY_TYPE_RETURN}
                    name="deliveryType"
                    onChange={handleChangeDeliveryType}
                    checked={state.deliveryType === DELIVERY_TYPE_RETURN}
                  />
                </FormColFlex>
              </Form.Group>
              <hr />
              {state.deliveryType === DELIVERY_TYPE_RETURN && (
                <Form.Group as={Row}>
                  <FormLabel column xs="2">
                    접수번호
                  </FormLabel>
                  <FormCol>
                    <FormControlWrap width="400px">
                      <FormControl
                        name="returnDeliveryId"
                        placeholder=""
                        ref={register}
                      />
                      <SearchButton
                        type="button"
                        width="100px"
                        onClick={handleClickSearchBookId}
                      >
                        검색
                      </SearchButton>
                    </FormControlWrap>
                  </FormCol>
                </Form.Group>
              )}

              <Form.Group as={Row}>
                <FormLabel column xs="2">
                  {state.deliveryType === DELIVERY_TYPE_NORMAL
                    ? "수하인명"
                    : "송하인명"}
                </FormLabel>
                <FormCol>
                  <FormControl
                    name="customerName"
                    ref={register({ required: true })}
                    width="400px"
                  />
                  <ErrorText>
                    {errors.customerName && "이름을 입력해주세요."}
                  </ErrorText>
                </FormCol>
              </Form.Group>
              <Form.Group as={Row}>
                <FormLabel column xs="2">
                  {state.deliveryType === DELIVERY_TYPE_NORMAL
                    ? "수하인 연락처"
                    : "송하인 연락처"}
                </FormLabel>
                <FormCol>
                  <FormControl
                    name="customerMobile"
                    placeholder="-를 빼고 입력해주세요."
                    ref={register({ required: true })}
                    width="400px"
                  />
                  <ErrorText>
                    {errors.customerMobile && "연락처를 입력해주세요."}
                  </ErrorText>
                </FormCol>
              </Form.Group>
              {state.deliveryType === DELIVERY_TYPE_RETURN && (
                <Form.Group as={Row}>
                  <FormLabel column xs="2">
                    송하인 연락처2
                  </FormLabel>
                  <FormCol>
                    <FormControl
                      name="customerMobile2"
                      placeholder="-를 빼고 입력해주세요."
                      ref={register}
                      width="400px"
                    />
                  </FormCol>
                </Form.Group>
              )}
              <Form.Group as={Row}>
                <FormLabel column xs="2">
                  주소
                </FormLabel>
                <FormCol>
                  <FormControlWrap>
                    <FormControl
                      name="customerAddress"
                      ref={register({ required: true })}
                      readOnly
                    />
                    <FormControl
                      name="customerAddressPostalCode"
                      ref={register({ required: true })}
                      hidden
                    />
                    <SearchButton
                      onClick={handleClickAddress}
                      type="button"
                      width="100px"
                    >
                      검색
                    </SearchButton>
                  </FormControlWrap>
                  <ErrorText>
                    {errors.customerAddress && "주소를 입력해주세요."}
                  </ErrorText>
                </FormCol>
              </Form.Group>
              <Form.Group as={Row}>
                <FormLabel column xs="2">
                  상세 주소
                </FormLabel>
                <FormCol>
                  <FormControl name="customerAddressDetail" ref={register} />
                </FormCol>
              </Form.Group>

              <hr />
              <Form.Group as={Row}>
                <FormLabel column xs="2">
                  물품 분류
                </FormLabel>
                <FormColFlex>
                  <Form.Check
                    inline
                    label="의류"
                    type="radio"
                    id="productCategory-0"
                    value="의류"
                    name="productCategory"
                    ref={register}
                  />
                  <Form.Check
                    inline
                    label="서신서류"
                    type="radio"
                    id="productCategory-1"
                    value="서신서류"
                    name="productCategory"
                    ref={register}
                  />
                  <Form.Check
                    inline
                    label="가전제품"
                    type="radio"
                    id="productCategory-2"
                    value="가전제품"
                    name="productCategory"
                    ref={register}
                  />
                  <Form.Check
                    inline
                    label="과일"
                    type="radio"
                    id="productCategory-3"
                    value="과일"
                    name="productCategory"
                    ref={register}
                  />
                  <Form.Check
                    inline
                    label="곡물"
                    type="radio"
                    id="productCategory-4"
                    value="곡물"
                    name="productCategory"
                    ref={register}
                  />
                  <Form.Check
                    inline
                    label="한약"
                    type="radio"
                    id="productCategory-5"
                    value="한약"
                    name="productCategory"
                    ref={register}
                  />
                  <Form.Check
                    inline
                    label="식품"
                    type="radio"
                    id="productCategory-6"
                    value="식품"
                    name="productCategory"
                    ref={register}
                  />
                  <Form.Check
                    inline
                    label="잡화서적"
                    type="radio"
                    id="productCategory-7"
                    value="잡화서적"
                    name="productCategory"
                    ref={register}
                  />
                  <Form.Check
                    inline
                    label="기타"
                    type="radio"
                    id="productCategory-8"
                    value="기타"
                    name="productCategory"
                    ref={register}
                  />
                </FormColFlex>
              </Form.Group>
              <Form.Group as={Row}>
                <FormLabel column xs="2">
                  물품명
                </FormLabel>
                <FormCol>
                  <FormControl
                    name="productName"
                    ref={register}
                    width="400px"
                  />
                </FormCol>
              </Form.Group>
              <Form.Group as={Row}>
                <FormLabel column xs="2">
                  물품 가격
                </FormLabel>
                <FormCol>
                  <FormControl
                    name="productPrice"
                    ref={register({ min: 0 })}
                    type="number"
                    width="400px"
                  />
                  <ErrorText>
                    {errors.productPrice && "물품 가격을 정확히 입력해주세요."}
                  </ErrorText>
                </FormCol>
              </Form.Group>
              <hr />
              {/* <Form.Group as={Row}>
                  <FormLabel column xs="2">지불 방식</FormLabel>
                  <FormColFlex>
                    <Form.Check
                      inline
                      label="업체"
                      type="radio"
                      id="pre-pay"
                      value="pre-pay"
                      name="paymentMethod"
                      ref={register}
                    />
                    <Form.Check
                      inline
                      label="고객"
                      type="radio"
                      id="after-pay"
                      value="after-pay"
                      name="paymentMethod"
                      ref={register}
                    />
                  </FormColFlex>
                </Form.Group>
                <hr /> */}
              <Form.Group as={Row}>
                <FormLabel column xs="2">
                  고객 요청 사항
                </FormLabel>
                <FormCol>
                  <FormControlTextarea
                    as="textarea"
                    name="memoFromCustomer"
                    ref={register}
                    rows={3}
                  />
                </FormCol>
              </Form.Group>
              {/*<hr />
               <Form.Group as={Row}>
                <FormLabel column xs="2">
                  접수 일자 지정
                </FormLabel>
                <FormColFlex>
                  <Form.Check
                    inline
                    label="바로 접수"
                    type="radio"
                    id="receiptType-1"
                    value="1"
                    name="receiptType"
                    onChange={handleChangeReceiptType}
                    checked={receiptType === "1"}
                  />
                  <Form.Check
                    inline
                    label="접수시간 지정"
                    type="radio"
                    id="receiptType-2"
                    value="2"
                    name="receiptType"
                    onChange={handleChangeReceiptType}
                    checked={receiptType === "2"}
                  />
                </FormColFlex>
              </Form.Group>
              <FormGroupDatePicker as={Row}>
                <DatePickerCol xs={{ span: 3, offset: 2 }}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="date"
                    render={({ onChange, onBlur, value }) => (
                      <DatePickerCustom
                        dateFormat="yyyy-MM-dd"
                        minDate={new Date()}
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        disabled={!dateSelectable}
                      />
                    )}
                  />
                </DatePickerCol>
                <DatePickerCol xs={2}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="hour"
                    render={({ onChange, onBlur, value }) => (
                      <DatePickerCustom
                        dateFormat="HH:mm"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        disabled={!dateSelectable}
                      />
                    )}
                  />
                </DatePickerCol>
              </FormGroupDatePicker> */}
              <Form.Group as={Row}>
                <FormCol xs={{ span: 10, offset: 2 }}>
                  <FormNotice>
                    *물품가격은 최대 50만원까지 보험적용됩니다. (50만원 이상
                    물품에 대해서는 별도문의 부탁드립니다.)
                  </FormNotice>
                  <Button type="submit" width="180px">
                    등록
                  </Button>
                </FormCol>
              </Form.Group>
            </Form>
          </Section>
        </Container>
      )}
    </BodyContent>
  );
}

// date / receiptHour / receiptMinute (00 39)
